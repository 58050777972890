#menu{

    width: 50px;
    height: 40px;
    margin: 30px 0 20px 20px;
    cursor: pointer;
  }
  .bar{
    position: absolute;
    right: 0vw;
    height: 5px;
    width: 50px;
    background-color: #e6e6e6;
    display: block;
    border-radius: 5px;
    
    z-index: 5;
  }
  #bar1{
    transform: translateY(-10px);
    transition: 0.3s ease;
  }
  #bar3{
    transform: translateY(10px);
    transition: 0.3s ease;
  }
  
  .navb{
    padding: 0;
    transition: 0.3s ease;
    display: none;
  }
  .navb li{
    list-style: none;
    padding: 16px 0;
  }
  
  #navLogob{
    width: 60px;
    position: fixed;
    left: 4vw;
    top: 10px;
    z-index: 3;
  }
  #navLogob:hover{
    cursor: pointer;
    width: 80px;
  }
  .navb li a{
    color: black;
    position: relative;
    text-decoration: none;
    font-size: 25px;
 
  }
  .navb li a:hover{
    color: #F22727;
    cursor: pointer;
  
  }

 
  
  .menu-bg, #menu-bar{
    top: 7px;
    right: 50px;
    position: fixed;
    text-align: right;
  }
  .menu-bg{
    z-index: 2;
    width:0;
    height: 120vh;
    margin: 30px 0 20px 20px;
    background:white;
    transform: translateX(500px);
    transition: 0.3s ease;
  }
  #menu-bar{
    z-index: 4;
  
  }
  .change-bg{
    width: 550px;
    height: 120vh;
    transform: translate(60%, -10%);
    transition: 0.3s ease;
  }
  .change{
    display: block; 
    
  }
  .change .bar{
    background-color: black;
  }
  .change #bar1{
    transform: rotateZ(45deg);
  }
  .change #bar2{
    opacity: 0;
  }
  .change #bar3{
    transform: rotateZ(-45deg);
  }
  
  