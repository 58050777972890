
html, body{
	height:100%;
	margin: 0;
	width: 100%;
	padding:0;
	text-align: center;
	background-color: white;
}
.backTopButton{
  font-size: 5vw;
  width: 5vw;
  height: 5vw;
  border-radius: 100%;
  background-color: white;
  color: #140F2D;
  border: solid #140F2D 0.3vw;
  position: fixed;
  bottom: 4vh;
  right: 4vw;
}
.backTopButton:hover{
  cursor: pointer;
  background-color: #140F2D;
  border:  0.3vw solid #140F2D;
  color: white;
}
.navA {
	position: absolute;
  top: 3vh;
  left: 5vw;
	z-index: 10;
	text-align: center;
  }


.homeLink{
    text-decoration: none;
    color: white;
    font-size: 25px;
}
.homeLink:hover {
	color: black;
	cursor: pointer;
  }


  

.topSectionA{

	background-image: url("/images/AkkrBackground.svg");
 
	background-position: center;
	background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 103vh;
  margin-top: -5px;
}

.topText{
  position: absolute;
  top: 30vh;
  left: 5vw;
}

.akkrLogo{
    position: absolute;
    width: 4vw;
    top: 85vh;
    left: 5vw;
}
.textA{
    text-align: left;
	  color: white;
    font-size: 60px;
    font-weight: bold;
    width: 700px;
}

.Aparagraph{
  
  width: 550px;
  color: white;
  text-align: left;

}
.akkrButtonWrap{
  text-align: left;
  margin-top: 2vw;
}

#akkrButton{
  text-decoration: none;
  background-color: white;
  font-size: 50px;
  color: #F22727;
  padding: 1vw;
  font-weight: bold;
  box-shadow: 0 0 50px rgb(0,0,0,0.3);
}

#akkrButton:hover{
  transform: scale(1.1);
}

#akkrButton:active{
  transform: scale(1);
}

.akkrParagraph{
    margin: 25px 0;
    padding: 1vw 10vw;
    text-align: left;
    background-color: rgb(248, 248, 248);
    color: grey;
    font-size: 30px;
}


.wrapper1{
    border-top: solid black 2px;
    border-bottom: solid black 2px;
    background-color: rgb(248, 248, 248);
}

.blackBorder{
  border-top: solid black 2px;
  border-bottom: solid black 2px;

}

.redBorder1{
  border-left: solid red 4px;
  border-right: solid red 4px;
  padding: 0 3vw;
  margin: 0 3vw;
}

.redBorder2{
  border-left: solid red 4px;
  border-right: solid red 4px;
  padding: 0 3vw;
}
.akkrSection{
  display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	justify-items: center;
	align-items: center;
  padding: 0;
  margin: 0 5vw;
  padding-bottom: 50px;

}
.sectionLogo{
  height: 20vh;

  
}

.akkrWrapper{
   
    text-align: left;
}

.akkrWrapper h1{
  margin: 0;
  padding: 20px 0;
}
.akkrWrapper p{
    width: 35vw;
    font-size: 30px;

}

.akkrImg{
    width: 15vw;
    float: left;
}
.akkrButton{
    background-color: red;
    color: white;
    padding: 15px 30px;
    border: none;
    font-size: 3vh;
    font-weight: 600;
    text-decoration: none;
}


@media screen and (max-width: 1000px) {

  .homeLink{
    text-decoration: none;
    color: white;
    font-size: 25px;
}

	.akkrParagraph{
    margin: 30px 0;
    padding: 1vw 10vw;
    text-align: left;
    background-color: rgb(248, 248, 248);
    color: grey;
    font-size: 22px;
}

.textA{
  text-align: left;
  color: white;
  font-size: 60px;
  font-weight: bold;
  width: 440px;
}

.Aparagraph{

width: 440px;
color: white;
text-align: left;

}

.akkrLogo{
  position: absolute;
  width: 8vw;
  top: 85vh;
  left: 5vw;
}

.akkrWrapper h1{
  margin: 0;
  padding: 20px 0;
}
.akkrWrapper p{
    width: 35vw;
    font-size: 20px;

}


#akkrButton{
  text-decoration: none;
  background-color: white;
  font-size: 32px;
  color: #F22727;
  padding: 1vw;
  font-weight: bold;
  box-shadow: 0 0 50px rgb(0,0,0,0.3);
}



}

@media screen and (max-width: 480px) {
  .backTopButton{
    font-size: 11vw;
    width: 11vw;
    height: 11vw;
    border-radius: 100%;
    background-color: white;
    color: #140F2D;
    border: solid #140F2D 0.7vw;
    position: fixed;
    bottom: 4vh;
    right: 4vw;
  }
  .backTopButton:hover{
    cursor: pointer;
    background-color: #140F2D;
    border:  0.7vw solid #140F2D;
    color: white;
  }

  .navA {
    position: absolute;
    top: 3vh;
    left: 5vw;
    z-index: 10;
    text-align: center;
    }
  .homeLink{
    text-decoration: none;
    color: white;
    font-size: 10vw;
    width: 50px;
    border: solid white 3px;
    border-radius: 100%;
    padding: 0 15px;
}
.homeLink:hover{
  text-decoration: none;
  color: #F22727;
  font-size: 10vw;
  width: 50px;
  border-radius: 100%;
  padding: 0 15px;
  background-color: white;
  border: solid white 3px;
}

	.topSectionA{
		background-image: url("/images/AkkrBackgroundMobile.svg");
    
		background-position: center;
		background-repeat: no-repeat;
    background-size: cover;
   
    width: 100%;
    
    
  }
  .topText{
    position: absolute;
    top: 45vh;
    left: 5vw;
    text-align: left;
  }

  .textA{
      text-align: left;
      color: white;
      font-size: 30px;
      font-weight: bold;
      width: 60vw;
  }
  .Aparagraph{
  
    width: 55vw;
    color: white;
    text-align: left;
    font-size: 12px;
  }

  .akkrLogo{
    position: absolute;
    width: 10vw;
    top: 90vh;
    left: 5vw;
}

#akkrButton{
  text-decoration: none;
  background-color: white;
  font-size: 25px;
  color: #F22727;
  padding: 1vw;
  font-weight: bold;
  box-shadow: 0 0 50px rgb(0,0,0,0.3);
  
}



  
  .akkrParagraph{
    margin: 25px 0;
    padding: 1vw 10vw;
    text-align: left;
    background-color: rgb(248, 248, 248);
    color: grey;
    font-size: 15px;
  }

  .redBorder2{
    border-left: solid red 6px;
    border-right: solid red 6px;
    padding: 0 5vw;
    margin: 0 5vw;
  }

  .akkrWrapper h1{
    margin: 0;
    padding: 15px 0;
  }
  .akkrWrapper p{
      width: 60vw;
      font-size: 20px;
  
  }
 
}

/*
Dark Blue: #140F2D
RED: #F22727*/