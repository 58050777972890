
html, body{
	height:100%;
	margin: 0;
	width: 100%;
	padding:0;
	text-align: center;
	font-family: 'Montserrat';

}

.wrapper{
	background-color: #F22727;
}
.nav {
	position: sticky;
	top: 0;
	z-index: 10;
	text-align: center;
  }

  .nav-logo {
	height: 70px;
	margin: auto 0;
	padding-left: 8vw;
  }
  
  .nav-logo:hover {
	cursor: pointer;
  }
  
  .nav .nav-content {
	width: 100%;	
	display: flex;
	justify-content: space-between;
	float: left;
	height: 80px;
  }

  .nav-items {
	padding-right: 5vw;
	font-size: 20px;
	padding-top: 0.5%;
	font-weight: bold;
  }
  
  .nav-item {
	display: inline-block;
	margin-left: 2rem;
    color: white;
  }
.akkLink{
    text-decoration: none;
    color: white;
}
.akkLink:hover {
	color: #F22727;
	cursor: pointer;
  }

  .nav-item:hover {
	color: #F22727;
	cursor: pointer;
  }
  
	
.topSection{
	background-image: url("/images/topBackground.svg");
	
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
	height: 140vh;
	width: 100%;
}

#shapes{
	width: 40vw;
	left:30vw;
	top: 15vh;
	position: absolute;
	z-index: 10;
	/*animation: testo 5s infinite;*/
}

@keyframes testo {
	0% {transform:translateY(0);}
	20% {transform:  translateY(3vw) translateX(-3vw);}
	40% {transform:  translateY(6vw) translateX(0);}
	60% {transform:  translateY(3vw) translateX(3vw);}
	80% {transform:  translateY(-0.5vw) translateX(0.8vw);}
	100%{transform:translateY(0);}
  }

.text{
	padding-top: 40vh;
	color: white;
	font-size: 4vh;
}

.topSection .text h1{
	padding: 0;
	margin: 0;
}



.overlapBox{
	transform: translateY(-40vh);
	background-color: white;
	margin: auto 20%;
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	padding-top: 3vw;
}
.overlapBox p{
	font-size: 2vw;
	margin-top:0;
	margin-bottom: 20px;
	font-weight: bold;
}
.overlapBox p span{
	color: #F22727;
}
.bereiche{
	justify-content:center;
	align-items:center;
	display: flex;
	font-size: 30px;
}

.bereiche ul{
	margin: 30px auto;
}

.bereiche ul li{
	padding: 20px;
	font-weight: 500;
	text-align: left;
}





.timeLine{
	position: relative;
	overflow-x: hidden;
	margin-top: -200px;
}

#timeLine{
	
	position: relative;
	
	width: 110%;
	margin-left: -3.5%;
	margin-bottom: 250px;
	z-index: 1;

}

.timeLineMobile{
	margin-top: -15vh;
	margin-left: 5vw;
	margin-bottom: 200px;
	position: relative;
	text-align: left;
}

#timeLineMobile{
	width: 80vw;
}


.textWrapper{
	color: white;
	position: absolute;
	font-size: 20px;
	left: 45vw;
	top: 17vh;
}

#line2, #line3{
	margin-top: 26vh;
}
.carousel{
	position: relative;
	
}
.image-slide{
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 70vh;
	clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
	position: relative;
	padding-top: 20vh;
}

.whiteBorder{
	background-color: white;
	background-size: cover;
	height: 70vh;
	z-index: -1;
	clip-path: polygon(0 19%, 100% 0, 100% 100%, 0 100%);
	transform: translateY(69.5vh);
	margin-top: -75vh;
	
}




.dotWrapper{
	display: flex;
	justify-content: center;
}

.dot{
	position: relative;
	top: -10vh;
	width: 1vw;
	height: 1vw;
	border-radius: 	100%;
	background-color: white;
	border: solid white 2px;
	margin-left: 10px;
}


.dotActive{
	
	background-color: transparent;
}
.customerWrapper{
	background-color: #F22727;
	padding-bottom: 100px;
}
.customer{
	
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 350px));
	position: relative;
	justify-items: center;
	align-items: center;
	
	justify-content: space-between;	
	margin-left: 10vw;
	margin-right: 10vw;
	max-height: 1fr;
	overflow-y: hidden;
}

.customerLogo{
	width: 200px;
	margin: 40px 0;
}

.customerButt{
	font-size: 40px;
	margin-right: 5vw;
	float: right;
	background-color: transparent;
	color: white;
	border: none;
	outline: none;
}




.paraGrid{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(650px, 1fr));
	position: relative;
	justify-items: center;
	align-items: center;
	width: 100%;
	background-color: white;
	margin: 0 auto;
}
.paragraph{
	
	width: 41vw;
	text-align: left;
	margin: 0 auto;
	margin-top: 100px;
}
.paragraph h1{
	font-size: 32px;
	font-weight: bold;
	text-align: left;
}
.para{
	font-size: 20px;
	line-height: 70px;
	max-height: 210px;
	overflow-y: hidden;
	text-align: left;
	overflow-x: auto;
	white-space: nowrap;
}

.paragraphImage{
	width: 30vw;
	height: 18vw;
	box-shadow: 0 15px 30px rgba(0,0,0,0.19), 0 10px 10px rgba(0,0,0,0.23);

}


.paragraphButt{
	font-size: 30px;
	margin-right: 30%;
	float: right;
	background-color: transparent;
	color: black;
	border: none;
	outline: none;
	
}

.more{
	font-style: bold;
	font-size: 37px;
	padding-top: 20px;
	padding-bottom: 50px;
	background-color: white;
}

.moreHeader{
	font-size: 40px;	
}

.team{
	background-color: rgb(4, 16, 58);
	
}
.header{
	color: red;
	font-size: 5vw;
	font-weight: light;
}
.teamGrid{
	color: white;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	position: relative;
	justify-items: center;
	align-items: center;
	padding: 50px 150px;
	
}

.member{
	padding-bottom:  50px;
}
.member h1{
	color:rgb(59, 121, 255);
}

.contactForm{
	background-color: white;
	width:100%;
	display: inline-flex;
	background-color: #140F2D;
}

#contactImg{
	display: inline-block;

	height: 30vw;
	transition: all 2s ease;
	padding: 0	10vw;
	padding-top: 30px;
}

.contactImgActive{
	animation: example 2s ease;
}
@keyframes example {
	
	0% {transform:translateY(0);}
		40% {transform:  translateY(-2000px);}
		50% {opacity: 0; transform:  translateY(2000px);}
		
		60% {opacity: 1; transform:  translateY(2000px);}
		100% {transform:translateY(0);}
  }


.mailForm{

	display: inline-block;
	width: 65%;
	padding-right:10vw;

}

.inputBox{
	outline: none;	
	border: none;
	border-bottom: solid white 1px;
	width: 100%;
	padding: 0;
	padding-top: 1vw;
	font-size: 1.5vw;
	vertical-align: bottom;
	font-family: 'Montserrat';
	background-color: transparent;
	color:white;
	
}

.inputBox::placeholder{
	color:white;
}
.boxError{
	border-bottom: solid red 2px;
	
}

#mailText{
	resize: none;
	padding-top: 3vh;
	height: 8vw;
	
}
#mailText:hover{
	cursor: text;
}

.checkBox{
	text-align: left;
}
#emailButton{
	outline: none;
	border: none;
	background-color: transparent;
	font-weight: bold;
	font-size: 2vw;
	margin: 3vw 0;	
	border-radius: 20px;
	color: white;
	float: left;
	
}

#emailButton:hover{
	transform: scale(1.1);
	text-shadow: 0 7px 7px rgb(0, 0, 0);
	cursor: pointer;

}
#emailButton:Active{
	text-shadow: none;
	transform: scale(1);
}





.bottomSection{
	margin-top: -4px;
	background-color: #140F2D;
	width: 100%;
	border-top: 5px solid rgb(0, 188, 245);
	display: inline-flex;
	border-bottom: 5px solid rgb(0, 188, 245);
}


#maps{
	
	border: 0;
	display: inline-block;
	width: 75%;
}

.contactPerson{
	display: inline-block;
	width: 25%;
	height: 100%
}
.header1{
	color: white;
	padding: 30px 0;
	font-size: 2vw;
	font-weight: 600;
}

.portrait{
	width: 10vw;
	border: solid 4px rgb(0, 188, 245);
	border-radius: 2vw;
	box-shadow: 0 0 20px  rgb(0, 188, 245, 1);
}

.header2{
	color: rgb(0, 188, 245, 1);
	font-weight: bold;
	padding: 20px 0;
}

.header3{
	color: white;
	padding-bottom: 5vh;
}

.footer{
	padding: 15px;
	background-color: #140F2D;
	margin-top: -20px;
}

.socialIcon{
	width: 20px;
}

.footerText{
	color:white;
	padding: 10px 0;
}



@media screen and (max-width: 1315px) {
	
.overlapBox{
	transform: translateY(-40vh);
	background-color: white;
	margin: auto 8%;
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	padding: 6vw;
}
.overlapBox p{
	font-size: 3vw;
	margin-top:0;
	margin-bottom: 10px;
	font-weight: bold;
}
.overlapBox p span{
	color: #F22727;
}
.bereiche{
	justify-content:center;
	align-items:center;
	display: flex;
	font-size: 28px;
}

.bereiche ul li{
	padding: 20px;
	font-weight: 500;
}

.paraWrapper{
	background-color: white;
}
	
.paraGrid{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
	position: relative;
	justify-items: center;
	align-items: center;
	width: 100%;
	background-color: white;
	
}
.paragraph{
	margin-top: 100px;
	width: 45vw;
	text-align: left;
}
.paragraph h1{
	font-size: 25px;
	text-align: left;
}
.para{
	font-size: 18px;
	max-height: 210px;
	line-height: 70px;
	overflow-y: hidden;
	text-align:left;
	overflow-x: auto;
	white-space: nowrap;
}



.paragraphImage{
	width: 35vw;
	height: 20vw;
	box-shadow: 0 15px 30px rgba(0,0,0,0.19), 0 10px 10px rgba(0,0,0,0.23);

}


.paragraphButt{
	font-size: 25px;
	margin-right: 5vw;
	float: right;
	background-color: transparent;
	color: black;
	border: none;
	outline: none;
}

.more{
	margin-top: 50px;
	font-style: bold;
	font-size: 30px;

}
.moreHeader{
	font-size: 30px;	
}

.header1{
	color: white;
	padding: 30px 0;
	font-size: 2.5vw;
	font-weight: 600;
}

.portrait{
	width: 12vw;
	border: solid 4px rgb(0, 188, 245);
	border-radius: 2vw;
	box-shadow: 0 0 20px  rgb(0, 188, 245, 1);
}
}

@media screen and (max-width: 980px) {
	.paraGrid{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
		position: relative;
		justify-items: center;
		align-items: center;
		width: 100%;
		background-color: white;
	
	}
	
	.paragraph{
		margin-top: 100px;
		width: 70vw;
		text-align: left;
	}
	.paragraph h1{
		font-size: 28px;
	}
	.para{
		font-size: 18px;
		max-height: 180px;
		overflow-y: hidden;
		line-height: 60px;
		overflow-x: auto;
		white-space: nowrap;
	}

	.paragraphImage{
		width: 60vw;
		height: 35vw;
		box-shadow: 0 15px 30px rgba(0,0,0,0.19), 0 10px 10px rgba(0,0,0,0.23);
	
	}

	.more{
		margin-top: 50px;
		font-style: bold;
		font-size: 30px;

	}

	.moreHeader{
		font-size: 25px;	
	}
	
	

}


@media screen and (max-width: 900px) {
	.nav-item {
		display: inline-block;
		margin-left: 0;
		color: white;
	  }


	  

	.bereiche{
		justify-content:center;
		align-items:center;
		display: flex;
		font-size: 20px;
	}
	
	.bereiche ul li{
		padding: 15px;
		font-weight: 500;
	}


	.customer{
	
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(100px, 250px));
		position: relative;
		justify-items: center;
		align-items: center;
		
		justify-content: space-between;	
		margin-left: 10vw;
		margin-right: 10vw;
		max-height: 150px;
		overflow-y: hidden;
	}
	
	.customerLogo{
		width: 150px;
		margin: 40px 0;
	}
	
	.customerButt{
		font-size: 40px;
		margin-right: 5vw;
		float: right;
		background-color: transparent;
		color: white;
		border: none;
		outline: none;
	}

	.paraGrid{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
		position: relative;
		justify-items: center;
		align-items: center;
		width: 100%;
		background-color: white;

	}
	.paragraph{
		margin-top: 100px;
		width: 70vw;
		text-align: left;
	}
	.paragraph h1{
		font-size: 28px;
	}
	.para{
		font-size: 18px;
		max-height: 210px;
		overflow-y: hidden;
		line-height: 70px;
		overflow-x: auto;
		white-space: nowrap;
	}



	.paragraphImage{
		width: 70vw;
		height: 35vw;
		box-shadow: 0 15px 30px rgba(0,0,0,0.19), 0 10px 10px rgba(0,0,0,0.23);
	
	}
	
	
	.paragraphButt{
		font-size: 25px;
		margin-right: 5vw;
		float: right;
		background-color: transparent;
		color: black;
		border: none;
		outline: none;
	}


	
	
	
	
	.inputBox{
		outline: none;	
		border: none;
		border-bottom: solid white 1px;
		width: 100%;
		padding: 0;
		padding-top: 1vw;
		font-size: 2vw;
		vertical-align: bottom;
		font-family: 'Montserrat';
		background-color: transparent;
		color:white;
	}
	.inputBox::placeholder{
		color:white;
	}
	
	#mailText{
		resize: none;
		padding-top: 2vh;
		height: 8vw;
		
	}

	.checkBox{
		text-align: left;
		font-size: 14px;
	}
	
	#emailButton{
		outline: none;
		border: none;
		background-color: transparent;
		font-weight: bold;
		font-size: 3vw;
		margin: 2vw 0;	
		color:white;
	}
	
  }


  @media screen and (max-width: 650px) {

	.bereiche{
		justify-content:center;
		align-items:center;
		display: flex;
		font-size: 15px;
	}
	
	.bereiche ul li{
		padding: 15px;
		font-weight: 500;
		margin-left: -15px;
	}

	.customer{
	
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(100px, 185px));
		position: relative;
		justify-items: center;
		align-items: center;
		justify-content: space-between;	
		margin-left: 10vw;
		margin-right: 10vw;
		max-height: 150px;
		overflow-y: hidden;
	}
	
	.customerLogo{
		width: 150px;
		margin: 40px 0;
	}
	
	.customerButt{
		font-size: 23px;
		margin-right: 5vw;
		float: right;
		background-color: transparent;
		color: white;
		border: none;
		outline: none;
	}

	.contactForm{
		width: 100%;
		background-color: #140F2D;
		display: block;
	}

	.mailForm{
		width: 80%;
		padding-right: 0;
	}
	#contactImg{
		
		width: 95vw;
		margin: 0;
		padding: 0;
		transition: all 2s ease;
		
	}

	
	.contactImgActive{
		animation: example2 2s ease;
	}
	@keyframes example2 {
		0% {transform: translateX(0);}
		40% {transform:  translateX(800px);}
		50% {opacity: 0; transform:  translateX(800px);}
		55% {opacity: 0; transform: translateX(-800px);}
		60% {opacity: 1; transform:  translateX(-800px);}
		100% {transform: translateX(0);}
		
	  }


	  .paraGrid{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
		position: relative;
		justify-items: center;
		align-items: center;
		width: 100%;
		background-color: white;

	}
	.paragraph{
		margin-top: 100px;
		width: 80vw;
		text-align: left;
	}
	.paragraph h1{
		font-size: 24px;
	}
	.para{
		font-size: 15px;
		max-height: 120px;
		overflow-y: hidden;
		line-height: 40px;
		overflow-x: auto;
		white-space: nowrap;
	}



	.paragraphImage{
		width: 80vw;
		height: 50vw;
		box-shadow: 0 15px 30px rgba(0,0,0,0.19), 0 10px 10px rgba(0,0,0,0.23);
	
	}
	
	
	.paragraphButt{
		font-size: 25px;
		margin-right: 5vw;
		float: right;
		background-color: transparent;
		color: black;
		border: none;
		outline: none;
	}

	.moreHeader{
		font-size: 20px;	
	}


	.inputBox{
		outline: none;	
		border: none;
		border-bottom: solid white 1px;
		width: 100%;
		overflow-x: hidden;
		font-family: 'Montserrat';
		padding: 0;
		padding-top: 7vw;
		font-size: 5vw;
		vertical-align: bottom;
		background-color: transparent;
		color:white;
	}
	.inputBox::placeholder{
		color:white;
	}
	.boxError{
		border-bottom: solid red 2px;
		
	}
	
	#mailText{
		resize: none;
		padding-top: 4vw;
		height: 35vw;
		
	}

	
	
	#emailButton{
		outline: none;
		border: none;
		float: left;
		background-color: transparent;
		font-weight: bold;
		font-size: 6vw;
		margin: 2vw 0;	
		color:white;
	}
	
	#emailButton:hover{
		transform: scale(1.1);
		text-shadow: 0 7px 7px rgb(0, 0, 0);
	
	
	}
	#emailButton:Active{
		text-shadow: none;
		transform: scale(1);
	}



	.bottomSection{
		margin-top: -5px;
		background-color: #140F2D;
		width: 100%;
		
		display: block;
		
	}
	
	
	#maps{
		
		border: 0;
		width: 100%;
		height: 50vh;
	}
	
	.contactPerson{
		width: 100%;
		
	}
	.header1{
		color: white;
		padding: 30px 0;
		font-size: 25px;
		font-weight: 600;
	}
	
	.portrait{
		width: 40vw;
		border: solid 4px rgb(0, 188, 245);
		border-radius: 2vw;
		box-shadow: 0 0 20px  rgb(0, 188, 245, 1);
	}
	
	.header2{
		color: rgb(0, 188, 245, 1);
		font-weight: bold;
		padding: 20px 0;
	}
	
	.header3{
		color: white;
		padding-bottom: 5vh;
	}
	
	
  }

@media screen and (max-width: 480px) {
	.topSection{
		background-image: url("/images/topBackgroundMobil.svg");
		
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
		height: 140vh;
		width: 100%;	
	}
	#shapes{
		display: none;
	}
	.text{
		padding-top: 40vh;
		color: white;
		font-size: 15px;
	}

	.customer{
	
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(100px, 160px));
		position: relative;
		justify-items: center;
		align-items: center;
		justify-content: space-between;	
		margin-left: 7vw;
		margin-right: 7vw;
		max-height: 100px;
		overflow-y: hidden;
	}
	
	.customerLogo{
		width: 100px;
		margin: 40px 0;
	}
	
	.customerButt{
		font-size: 23px;
		margin-right: 5vw;
		float: right;
		background-color: transparent;
		color: white;
		border: none;
		outline: none;
	}

	
	.paraGrid{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		position: relative;
		justify-items: center;
		align-items: center;
		width: 100%;
		background-color: white;
	
	}
	.paragraph{
		margin-top: 100px;
		width: 92vw;
		text-align: left;
		
	}
	.paragraph h1{
		font-size: 24px;
	}
	.para{
		font-size: 14px;
		max-height: 180px;
		overflow-y: hidden;
		line-height: 60px;
		overflow-x: auto;
		white-space: nowrap;
		
	}

	.paragraphImage{
		width: 92vw;
		box-shadow: 0 15px 30px rgba(0,0,0,0.19), 0 10px 10px rgba(0,0,0,0.23);
	
	}

	.paragraphButt{
		font-size: 20px;
		margin-right: 5vw;
		float: right;
		background-color: transparent;
		color: black;
		border: none;
		outline: none;
	}

	


	.footer{
		padding: 15px;
		background-color: #140F2D;
		margin-top: -20px;
	}
	
	.socialIcon{
		width: 20px;
	}
	
	.footerText{
		color:white;
		padding: 10px 0;
		font-size: 15px;
	}
	
  }

  @media screen and (max-width: 390px) {

	.customer{
	
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(50px, 100px));
		position: relative;
		justify-items: center;
		align-items: center;
		
		justify-content: space-between;	
		margin: 0 8vw;
		max-height: 120px;
		overflow-y: hidden;
	}
	.customerLogo{
		width: 80px;
		margin: 40px 0;
	}

  }

  .extended{
	max-height: none;

	
}

.para::-webkit-scrollbar {
    -webkit-appearance: none;
}

.para::-webkit-scrollbar:horizontal {
    height: 11px;
}

.para::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .5);
}
.para::-webkit-scrollbar-track { 
    background-color: #fff; 
    border-radius: 8px; 
}
/*
Dark Blue: #140F2D
RED: #F22727*/